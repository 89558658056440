<template>
    <div class="mb-4">
        <div class="mb-4">
            <CRow class="mb-0">
                <CCol lg="3">
                    <CInput v-model="customer_search" placeholder="Customer" input-class="form-control"/>
                </CCol>
                <CCol lg="2">
                    <Datepicker v-model="date_from" input-class="form-control"
                        placeholder="Date created from" />
                </CCol>
                <CCol lg="2">
                    <Datepicker v-model="date_to" input-class="form-control"
                        placeholder="Date created to" />
                </CCol>
                <CCol lg="2" class="ml-auto">
                    <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="2" class="ml-auto">
                    <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';
export default {
    name: "clientABillingSummary",
    data() {
        return {
            date_from: "",
            date_to: "",
            customer_search: "",
        }
    },
    components: { vSelect, Datepicker },
    methods: {
        search() {
            const data = {
                date_from: this.date_from,
                date_to: this.date_to,
                customer_search: this.customer_search,
            }
            this.$emit("depot-search-query", data)
        },
        clearSearch() {
            this.date_from = "";
            this.date_to = "";
            this.customer_search = "";
            const data = {
                date_from: this.date_from,
                date_to: this.date_to,
                customer_search: this.customer_search,
            }
            this.$emit("depot-search-query", data)
        },
    }
}
</script>

<style scoped></style>
