<template>
	<div>
		<CCard class="p-4">
			<CRow>
				<CCol lg="6">
					<h5>Client Billing Summary Report ({{ count }}) </h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
					</CButton>
					<CButton 
						v-if="config.getPermission('client_billing_summary_reports').download"
				 		size="sm" style="margin-right: 10px" 
						color="primary" @click="download" 
						class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>
			<CRow>
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CDataTable :loading="isLoading" :items="dataListAll.data" :fields="fields" hover striped
							outlined>
                            <template #total_amount="{ item }">
                                <td style="padding: 5px;">
                                    {{ computeTotalAmount(item.client_billing)|CurrencyFormatWithSign}}
                                </td>
                            </template>
                            <template #total_payment="{ item }">
                                <td style="padding: 5px; ">
                                    {{ computeTotalPayment(item.client_billing)|CurrencyFormatWithSign}}
                                </td>
                            </template>
                            <template #balance="{ item }">
                                <td style="padding: 5px; ">
                                    {{computeTotalAmount(item.client_billing) - computeTotalPayment(item.client_billing)|CurrencyFormatWithSign}}
                                </td>
                            </template>
                           
						</CDataTable>
						<CPagination :active-page.sync="currentPageAll" :pages="Math.ceil(dataListAll.total / 20)"
							:activePage="currentPageAll" @update:activePage="updatePageAll" />
					</div>
				</CCol>
			</CRow>
		</CCard>
	</div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './clientBillingSummarySearch.vue';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../style.css';

export default {
	mounted() {
		this.getDataAll();
	},
	data() {
		return {
			current_tab: "All",
			title: '',
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataListAllEquipment: [],
			dataList: [],
			equipmentCurrentLocationList: [],
			dataListAll: {
				data: []
			},
			depotName: "",
			dataParams: {
				
			},
			fields: [
				{
					key: 'customer_name',
					label: 'Customer'
				},
				{
					key: 'total_amount',
					label: 'Total Amount'
				},
                {
					key: 'total_payment',
					label: 'Total Payment'
				},
                {
					key: 'balance',
					label: 'Balance'
				},
			],
			currentPageAll: 1,
			showCollapse: false,
			filter: {
                date_from:'',
                date_to:'',
                customer_search:'',
			},
			depotList: {
				data: []
			},
			moment,
		}
	},
	name: 'Tables',
	computed: {
		count: function(){
			return this.dataListAll.data.length;
		},
	},
	components: { Search, Datepicker, vSelect },
	methods: {
		updatePageAll(data) {
			this.currentPageAll = data;
			this.getDataAll();
		},
		search(event) {
            console.log(event);
			this.filter = event;
			this.getDataAll();
		},
		getDataAll() {
			var company_id = config.global_user_data.company_id;
			this.isLoading = true;
			axios.get(config.api_path + '/client-billing-summary?customer_search=' + this.filter.customer_search +
                '&date_from=' +  moment(this.filter.date_from).format('YYYY-MM-DD') +
                '&date_to=' + moment(this.filter.date_to).format('YYYY-MM-DD') +
                '&company_id=' + company_id +
                '&page=' + this.currentPageAll + '&limit=20')
				.then(response => {
					this.dataListAll = response.data;
				})
				.catch(err => {
					console.error(err)
					this.isLoading = false;
				})
				.finally(fin => {
					console.log(fin)
					this.isLoading = false;
				})
		},
		download(){ 
			var company_id = config.global_user_data.company_id;
			this.$showLoading(true);
	    	axios.get(config.api_path+'/reports/client-billing-summary?customer_search='+this.filter.customer_search +
				'&date_from=' +  moment(this.filter.date_from).format('YYYY-MM-DD') +
                '&date_to=' + moment(this.filter.date_to).format('YYYY-MM-DD') +
				'&company_id=' + company_id +
                '&page=' + this.currentPageAll + '&limit=20')
				.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

	    		setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },
        computeTotalAmount(data){
			console.log(data);
            var total = 0;
            data.forEach(e => {
                total += parseFloat(e.total_amount);
            });
            return total;
        },
        computeTotalPayment(data){
			console.log(data);
            var total = 0;
            data.forEach(e => {
                e.payment_received.forEach(payment => {
                    total += parseFloat(payment.amount);
                });
            });
            return total;
        }
	}
}
</script>
